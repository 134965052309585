import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../pages/header";
import Footer from "../pages/footer";
import * as API from "../endpoint";

const ITEMS_PER_PAGE = 10;

const SecondTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const loadData = async () => {
    const response = await axios.get(API.OBJECTGET_OBJECTNAME_API);
    setData(response.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  const deleteObject = async (nameid) => {
    if (window.confirm("Are you sure you want to delete")) {
      await axios.delete(API.OBJECTDELETE_OBJECTNAME_API(nameid));
      console.log("success:", "deleted successfully");
      loadData();
    }
  };

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <Header />
      <div style={{ marginTop: "10px" }}>
        <Link to="/addobj">
          <center>
            {" "}
            <button className="btn btn-contact">Add Object</button>
          </center>
        </Link>

        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>No</th>
                <th style={{ textAlign: "center" }}>Object Type</th>
                <th style={{ textAlign: "center" }}>Object code</th>
                <th style={{ textAlign: "center" }}>Object Type Description</th>
                <th style={{ textAlign: "center" }}>Dependent Object code</th>
                <th style={{ textAlign: "center" }}>Icon Upload</th>
                <th style={{ textAlign: "center" }}>File Upload</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.id}>
                  <th scope="row">{indexOfFirstItem + index + 1}</th>
                  <td>{item.objecttype}</td>
                  <td>{item.objectcode}</td>
                  <td>{item.objectdescription}</td>
                  <td>{item.dependentobjectcode}</td>
                  <td>{item.iconupload}</td>
                  <td>{item.fileupload}</td>
                  <td>
                    <Link to={`/edit/${item.nameid}`}>
                      <button className="btn btn-edit">Edit</button>
                    </Link>
                    <button
                      className="btn btn-delete"
                      onClick={() => deleteObject(item.nameid)}
                    >
                      Delete
                    </button>
                    <Link to={`/see/${item.nameid}`}>
                      <button className="btn btn-view">View</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <ul className="pagination">
            {Array.from(
              { length: Math.ceil(data.length / ITEMS_PER_PAGE) },
              (_, index) => (
                <li
                  key={index}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  <button onClick={() => paginate(index + 1)}>
                    {index + 1}
                  </button>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SecondTable;
