import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
/*----------------object type----------------*/

import Table1 from "./firstTable/Table1";
import AddEdit from "./firstTable/AddEdit";
import View from "./firstTable/View";

/*----------------obect name-----------*/
import SecondTable from "./secondTable/SecondTable";
import AddEditSecond from "./secondTable/AddEditSecond";
import SecondViewTable from "./secondTable/SecondViewTable";

/*-------------------------Responsibility Group-----------------------*/
import ResponsibilityTable from "./ResponsibilityGroup/ResponsibilityTable";
import ResponsibilityAddEdit from "./ResponsibilityGroup/ResponsibilityAddEdit";
import ResponsibilityView from "./ResponsibilityGroup/ResponsibilityView";

/*-------------------------Responsibility Center-----------------------*/
import RespAddEdit from "./ResponsibilityCenter/RespAddEdit";
import RespView from "./ResponsibilityCenter/RespView";
import RespTable from "./ResponsibilityCenter/RespTable";

/*-------------------------Parameter_Category-----------------------*/
import ParameterTable from "./ParameterCategory/ParameterTable";
import ParamterAddEdit from "./ParameterCategory/ParamterAddEdit";
import ParamterView from "./ParameterCategory/ParamterView";

/*-------------------------Parameter-----------------------*/
import ParTable from "./Parameter/ParTable";
import ParView from "./Parameter/ParView";
import ParAddEdit from "./Parameter/ParAddEdit";

/*-------------------Risk Category-----------------------------*/
import RiskTable from "./RiskCategory/RiskTable";
import RiskAddEdit from "./RiskCategory/RiskAddEdit";
import RiskView from "./RiskCategory/RiskView";

/*-------------------Sub Category-----------------------------*/
import SubCategoryTable from "./SubCategory/SubCategoryTable";
import SubCategoryView from "./SubCategory/SubCategoryView";
import SubCategoryAddEdit from "./SubCategory/SubCategoryAddEdit";

/*-------------------Risk Group-----------------------------*/
import RiskGroupTable from "./RiskGroup/RiskGroupTable";
import RiskGroupAddEdit from "./RiskGroup/RiskGroupAddEdit";
import RiskGroupView from "./RiskGroup/RiskGroupView";

/*-------------------Risk serverity-----------------------------*/
import RiskSeverityTable from "./RiskSeverity/RiskSeverityTable";
import RiskSeverityAddEdit from "./RiskSeverity/RiskSeverityAddEdit";
import RiskSeverityView from "./RiskSeverity/RiskSeverityView";

/*-------------------Risk-----------------------------*/
import TableRisk from "./Risk/TableRisk";
import Risk1 from "./Risk/Risk1";
import ViewRisk from "./Risk/ViewRisk";
import ControlUpdate from "./Risk/ControlUpdate";
import ControlRiskTable from "./Risk/ControlRiskTable";
import ResidualRiskChart from "./Risk/ResidualRiskChart";
import Task from "./Risk/Task";

/*-------------------Company-----------------------------*/
import Company from "./Company/Company";
import CompanyView from "./Company/CompanyView";
import AddEditCompany from "./Company/AddEditCompany";

/*-------------------------------------------------------*/
import Project from "./project/Project";
import AddEditproject from "./project/AddEditproject";

/*----------------------------------------------------*/
import AIMaster from "./AIMaster/AIMaster";
import RiskExposureHeatMap from "./Risk/RiskExposureHeatMap";
import RiskGroupGraph from "./categoryGraph/RiskGroupGraph";
import RiskSubCategoryGraph from "./categoryGraph/RiskSubCategoryGraph";
import RiskSeverityGraph from "./categoryGraph/RiskSeverityGraph";
import RiskCategoryGraph from "./categoryGraph/RiskCategoryGraph";
import KanbanBoardRisk from "./KanbanBoard/KanbanBoardRisk";
import RiskChecklist from "./Checklist/RiskChecklist";
import ChecklistTable from "./Checklist/ChecklistTable";

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Risk1 />} />
          {/*----------------object type----------------*/}
          <Route path="/objtype" element={<Table1 />} />
          <Route path="/addobject" element={<AddEdit />} />
          <Route path="/update/:objectid" element={<AddEdit />} />
          <Route path="/view/:objectid" element={<View />} />

          {/*----------------obect name-----------*/}
          <Route path="/secondTable" element={<SecondTable />} />
          <Route path="/addobj" element={<AddEditSecond />} />
          <Route path="/edit/:nameid" element={<AddEditSecond />} />
          <Route path="/see/:nameid" element={<SecondViewTable />} />

          {/*-------------------------Responsibility Group-----------------------*/}
          <Route path="/res" element={<ResponsibilityTable />} />
          <Route path="/resTable" element={<ResponsibilityAddEdit />} />
          <Route
            path="/change/:Responsibility"
            element={<ResponsibilityAddEdit />}
          />
          <Route
            path="/seen/:responsibilityid"
            element={<ResponsibilityView />}
          />

          {/*-------------------------Responsibility Center-----------------------*/}
          <Route path="/resp2" element={<RespTable />} />
          <Route path="/restable2" element={<RespAddEdit />} />
          <Route
            path="/convert/:responsibilitynameid"
            element={<RespAddEdit />}
          />
          <Route path="/focus/:responsibilitynameid" element={<RespView />} />

          {/*-------------------------Parameter_Category-----------------------*/}
          <Route path="/par" element={<ParameterTable />} />
          <Route path="/parTable" element={<ParamterAddEdit />} />
          <Route
            path="/parameterupdate/:categoryid"
            element={<ParamterAddEdit />}
          />
          <Route path="/parameterView/:categoryid" element={<ParamterView />} />

          {/*-------------------------Parameter-----------------------*/}
          <Route path="/parameter" element={<ParTable />} />
          <Route path="/parameterTable" element={<ParAddEdit />} />
          <Route path="/parput/:parameterid" element={<ParAddEdit />} />
          <Route path="/parView/:parameterid" element={<ParView />} />

          {/*-------------------Risk Category-----------------------------*/}
          <Route path="/risk" element={<RiskTable />} />
          <Route path="/riskTable" element={<RiskAddEdit />} />
          <Route path="/riskupdate/:riskcategoryid" element={<RiskAddEdit />} />
          <Route path="/riskView/:riskcategoryid" element={<RiskView />} />

          {/*-------------------Sub Category-----------------------------*/}
          <Route path="/sub" element={<SubCategoryTable />} />
          <Route path="/subTable" element={<SubCategoryAddEdit />} />
          <Route
            path="/subupdate/:subcategoryid"
            element={<SubCategoryAddEdit />}
          />
          <Route
            path="/riskView/:subcategoryid"
            element={<SubCategoryView />}
          />

          {/*-------------------Risk group-----------------------------*/}
          <Route path="/riskgroup" element={<RiskGroupTable />} />
          <Route path="/riskgroupTable" element={<RiskGroupAddEdit />} />
          <Route
            path="/riskgroupupdate/:riskgroupid"
            element={<RiskGroupAddEdit />}
          />
          <Route
            path="/riskgroupView/:riskgroupid"
            element={<RiskGroupView />}
          />

          {/*-------------------Risk Severity-----------------------------*/}
          <Route path="/riskseverity" element={<RiskSeverityTable />} />
          <Route path="/riskseverityTable" element={<RiskSeverityAddEdit />} />
          <Route
            path="/riskseverityupdate/:riskseverityid"
            element={<RiskSeverityAddEdit />}
          />
          <Route
            path="/riskseverityView/:riskseverityid"
            element={<RiskSeverityView />}
          />

          {/*-------------------Risk -----------------------------*/}
          <Route path="/riskmanagement" element={<Risk1 />} />
          <Route path="/TableRisks" element={<TableRisk />} />
          <Route path="/risksupdate/:riskid" element={<TableRisk />} />
          <Route path="/Viewview/:riskid" element={<ViewRisk />} />
          <Route path="/controlupdate/:riskid" element={<ControlUpdate />} />
          <Route
            path="/risktassessmenttable/:riskcode"
            element={<ControlRiskTable />}
          />
          <Route
            path="/residualriskchart/:riskcode"
            element={<ResidualRiskChart />}
          />
          <Route
            path="/riskexposureheatmap/:riskcode"
            element={<RiskExposureHeatMap />}
          />
          <Route path="/tisk" element={<Task />} />
          {/*--------------------------------Company----------------------------------- */}

          <Route path="/company" element={<Company />} />
          <Route path="/addcompany" element={<AddEditCompany />} />
          <Route path="/editcompany/:companyid" element={<AddEditCompany />} />
          <Route path="/companyView/:companyid" element={<CompanyView />} />

          {/*--------------------------------Project ----------------------------------- */}

          <Route path="/project" element={<Project />} />
          <Route path="/addproject" element={<AddEditproject />} />
          <Route path="/projectedit/:projectid" element={<AddEditproject />} />
          <Route
            path="/riskexposureheatmap"
            element={<RiskExposureHeatMap />}
          />
          {/*------------------------------AIMaster-------------------------------------------*/}
          <Route path="/aimaster" element={<AIMaster />} />

          {/*****************Risk Graph*********************** */}
          <Route path="/riskcategory" element={<RiskCategoryGraph />} />
          <Route
            path="/risksubcategory/:category"
            element={<RiskSubCategoryGraph />}
          />
          <Route
            path="/riskseveritygraph/:riskgroup"
            element={<RiskSeverityGraph />}
          />
          {/* <Route path="/combinedriskgraphs" element={<CombinedRiskGraphs />} /> */}
          <Route
            path="/riskgroupgraph/:subcategory"
            element={<RiskGroupGraph />}
          />
          <Route path="/kanbanrisk" element={<KanbanBoardRisk />} />
          <Route path="/riskChecklist/:riskid" element={<RiskChecklist />} />
          <Route path="/checklistTable/:riskid" element={<ChecklistTable />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
